<template>
    <div v-if="invitedToGames.length && !isInGame" class="math-runner-invite">
        <div class="math-runner-invite--content">
            <div class="content">
                <div class="content__inner">
                    <div class="content_header">
                        <div class="text black text-body-l">
                            PLAY ARENA GAME
                        </div>
                        <div class="close">
                            <img
                                src="/images/bp-coupon/close-btn.svg"
                                alt="close"
                                @click="close"
                            />
                        </div>
                    </div>
                    <div class="invites-list">
                        <div
                            v-for="invite in invitedToGames"
                            :key="invite.hostPlayer.id"
                            class="invites-item row"
                        >
                            <div class="player-info">
                                <CustomAvatar
                                    :avatar-size="60"
                                    :custom-avatar="
                                        invite.hostPlayer.customAvatar
                                    "
                                    :background="invite.hostPlayer.background"
                                    :frame="invite.hostPlayer.frame"
                                />
                                <div class="name">
                                    {{ invite.hostPlayer.playerName }}
                                </div>
                            </div>
                            <div class="text text-body-s">
                                {{
                                    !invite.started
                                        ? `Invited you to a new game (${invite.gameMode})`
                                        : 'Sorry! Game has already started'
                                }}
                            </div>
                            <ButtonSmall
                                v-if="!invite.started"
                                :type="ButtonType.Primary"
                                text="JOIN"
                                @click="joinGame(invite)"
                            />
                            <ButtonSmall
                                v-if="!invite.started"
                                :type="ButtonType.Destructive"
                                font-size="22"
                                text="DECLINE"
                                @click="decline(invite)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ButtonType } from '@/student/ui/button/ButtonMedium.vue';
import ButtonSmall from '@/student/ui/button/ButtonSmall.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import CustomAvatar from '@/core/components/Avatar/CustomAvatar.vue';

export default {
    components: { CustomAvatar, ButtonSmall },
    data: () => ({
        ButtonType,
    }),
    computed: {
        ...mapState('v2/mathRunner', ['invitedToGames']),
        isInGame() {
            return [
                'math-runner.lobby',
                'math-runner.game-pregame',
                'math-runner.game',
                'math-runner.game-end',
                'livegame.preview',
                'livegame.gameview',
                'livegame.results',
                'livegame.warmup',
            ].includes(this.$route.name);
        },
    },
    methods: {
        joinGame(invite) {
            if (!invite.started) {
                this.$router.push({
                    name: 'join.gamecode',
                    params: { gameCode: invite.gameCode },
                });
            }

            this.$store.dispatch('v2/mathRunner/delInvite', invite);

            this.close();
        },
        decline(invite) {
            this.$store.dispatch('v2/sse/emitEvent', {
                channel: 'app',
                data: {
                    receiverId: invite.hostPlayer.userId,
                    invitedPlayerId: invite.invitedPlayer.userId,
                    gameCode: invite.gameCode,
                    type: 'runnerDeclinedInvite',
                    declined: moment().format(),
                },
            });

            this.$store.dispatch('v2/mathRunner/delInvite', invite);

            new TrackingService().track(EVENTS.DECLINE_ARENA_GAME_INVITATION, {
                gameType: invite.gameMode,
            });
        },
        close() {
            this.invitedToGames.forEach((invite) => this.decline(invite));
        },
    },
};
</script>
<style lang="scss">
.math-runner-invite {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1003;

    &--content {
        text-align: center;
    }
}

.content {
    width: 100%;

    .content__inner {
        width: 100%;
        height: 100%;
        max-height: 100vh;
        background: linear-gradient(180deg, #e34fd0 0%, #6e0080 100%);
        display: flex;
        flex-direction: column;

        .content_header {
            background: var(--Purple-700, #450c54);
            box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.25);
            height: 64px;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
        }

        .close {
            position: absolute;
            right: 10px;
            cursor: pointer;

            @include mq($w-max-phone, max, width) {
                right: 5px;
            }
        }
    }
}

.invites-list {
    height: 250px;
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
    padding: 10px;
    gap: 15px;
    overflow-y: auto;
    overflow-x: clip;

    @include mq($w-max-phone, max, width) {
        height: unset;
        width: unset;
    }
}

.invites-item {
    display: flex;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.25);
    background: #6e0080;
    width: 100%;
    gap: 10px;
    padding: 10px;

    .player-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        .name {
            font-size: 16px;
            font-style: italic;
            font-weight: 600;
        }
    }

    .info {
        font-weight: 500;
        text-transform: uppercase;
    }

    &.row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        @include mq($w-max-phone, max, width) {
            flex-wrap: wrap;
        }
    }

    &:hover {
        background: lighten(#6e0080, 10%);
        cursor: pointer;
    }
}

.button-small .text-wrapper {
    font-size: 21px;
}
</style>
